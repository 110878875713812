import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

import { Avatar, Breadcrumbs } from '../../../components';
import i18n from '../../../i18n';
import { linkNormalize } from '../../../utils';

import '../index.scss';
import { SETTING_PROFILE_PV_INSTALLER_USERS_URL } from '../../../api/apiUrls';

const CompanyInfo = (props) => {
  const { data = {}, loading } = props;
  const phoneNumber = formatPhoneNumberIntl(data.phone) || data.phone;

  if (loading) {
    return (
      <div className="whiteStatusText">
        {i18n.t('load')}
        ...
      </div>
    );
  }

  if (!data?.my_company) {
    return <div className="whiteStatusText">{i18n.t('companyNotFound')}</div>;
  }

  return (
    <div className="container-fluid">
      <Breadcrumbs crumbs={[{ name: i18n.t('pvInstallers'), url: '/pv-installers' }, { name: data.my_company.name }]} />

      <div className="container-fluid" style={{ marginBottom: '15px' }}>
        <div className="row align-items-center">
          <div className="company-logo avatar-logo col-auto">
            <div className="row justify-content-center">
              <Avatar
                className="big"
                name={data.my_company.name}
                avatar={data.avatar ? data.avatar.url : false}
              />
            </div>
          </div>
          <div className="col-auto mr-auto">
            <table className="company-details-table">
              <tbody>
                <tr className="m-datatable__row">
                  <td className="m-datatable__cell table-tariff-header">
                    <span className="tarif-header">
                      {i18n.t('company')}
                      :
                      {' '}
                    </span>
                  </td>
                  <td data-field="Websit" className="m-datatable__cell">
                    <span className="company-detail-td">
                      <span className="table-details-user-nifo">{data.my_company.name}</span>
                    </span>
                  </td>
                  <td className="m-datatable__cell table-tariff-header">
                    <span className="tarif-header">
                      {i18n.t('street')}
                      :
                      {' '}
                    </span>
                  </td>
                  <td className="m-datatable__cell">
                    <span className="table-details-user-info-value-padding">
                      <span className="table-details-user-nifo">{data.street}</span>
                    </span>
                  </td>
                </tr>
                <tr className="m-datatable__row">
                  <td className="m-datatable__cell table-tariff-header">
                    <span className="tarif-header">
                      {i18n.t('fName')}
                      :
                      {' '}
                    </span>
                  </td>
                  <td data-field="Websit" className="m-datatable__cell">
                    <span className="company-detail-td">
                      <span className="table-details-user-nifo">
                        {data.first_name}
                        {' '}
                        {data.last_name}
                      </span>
                    </span>
                  </td>
                  <td className="m-datatable__cell table-tariff-header">
                    <span className="tarif-header">
                      {i18n.t('city')}
                      :
                      {' '}
                    </span>
                  </td>
                  <td className="m-datatable__cell">
                    <span className="table-details-user-info-value-padding">
                      <span className="table-details-user-nifo">{data.city}</span>
                    </span>
                  </td>
                </tr>
                <tr className="m-datatable__row">
                  <td className="m-datatable__cell table-tariff-header">
                    <span className="tarif-header">
                      {i18n.t('mail')}
                      :
                      {' '}
                    </span>
                  </td>
                  <td className="m-datatable__cell table-comp-mail">
                    <span
                      className="table-mail-hove-ef company-detail-td email-text-td email-text-td-additional-styles"
                    >
                      <a href={`mailto:${data.email}`}>
                        {data.email}
                      </a>
                    </span>
                  </td>
                  <td className="m-datatable__cell table-tariff-header">
                    <span className="tarif-header">
                      {i18n.t('zip')}
                      :
                      {' '}
                    </span>
                  </td>
                  <td className="m-datatable__cell">
                    <span className="table-details-user-info-value-padding">
                      <span className="table-details-user-nifo">{data.zip}</span>
                    </span>
                  </td>
                </tr>
                <tr className="m-datatable__row">
                  <td className="m-datatable__cell table-tariff-header">
                    <span className="tarif-header">
                      {i18n.t('webpage')}
                      :
                      {' '}
                    </span>
                  </td>
                  <td className="m-datatable__cell">
                    <span
                      className="table-mail-hove-ef company-detail-td email-text-td-additional-styles"
                    >
                      <a
                        href={linkNormalize(data.webpage)}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="black"
                      >
                        {data.webpage}
                      </a>
                    </span>
                  </td>
                  <td className="m-datatable__cell table-tariff-header">
                    <span className="tarif-header">
                      {i18n.t('phone')}
                      :
                      {' '}
                    </span>
                  </td>
                  <td className="m-datatable__cell">
                    <span className="table-details-user-info-value-padding">
                      <span className="table-details-user-nifo">
                        {phoneNumber}
                      </span>
                    </span>
                  </td>
                </tr>
                <tr className="m-datatable__row">
                  <td className="m-datatable__cell table-tariff-header">
                    <span className="tarif-header">
                      {i18n.t('status')}
                      :
                    </span>
                  </td>
                  <td className="m-datatable__cell">
                    <span
                      className="m-badge gener-sett-soft-select m-badge--metal m-badge--wide m-badge--wide-additional-styles"
                    >
                      {i18n.t(data.status)}
                    </span>
                  </td>
                  <td className="m-datatable__cell table-tariff-header">
                    <span className="tarif-header">
                      {i18n.t('country')}
                      :
                    </span>
                  </td>
                  <td className="m-datatable__cell">
                    <span className="table-details-user-info-value-padding">
                      <span className="table-details-user-nifo">
                        {data.country}
                      </span>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-auto align-self-start">
            <Link
              title={i18n.t('editProfile')}
              to={`${SETTING_PROFILE_PV_INSTALLER_USERS_URL}/${data._id || data.id}/`}
              className="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
            >
              <i className="la la-edit" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

CompanyInfo.propTypes = {
  data: PropTypes.instanceOf(Object),
  loading: PropTypes.bool
};

export default CompanyInfo;
